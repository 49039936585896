import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SendSms, { clickSendSms } from "../components/SendSMS";

export const SendSmsPage = () => {
  const [searchParams] = useSearchParams();

  const phoneNumber = searchParams.get("phoneNumber");
  const smsMessage = searchParams.get("smsMessage");

  useEffect(() => {
    clickSendSms(smsMessage, phoneNumber);
  }, [smsMessage, phoneNumber]);

  return (
    <main className="center_page">
      <div>
        <p style={{ marginBottom: 40 }}>Click below to text the recipient</p>
        <SendSms
          identifier={phoneNumber}
          disabled={false}
          message={smsMessage}
        ></SendSms>
      </div>
    </main>
  );
};

export default SendSmsPage;
