import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { DataContext } from "../dataContext";
import useApi from "../api/api";

const NPEventDemo = ({ setFromDemoPage }) => {
  const { TRANSFER_PROCESSING } = ROUTE_CONSTANTS;
  const { accounts, uniqueCardsWithCKPreference, loggedIn } = useContext(DataContext);
  const api = useApi(false);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isValidInput, setIsValidInput] = useState(false);
  const [fromAccount, setFromAccount] = useState(undefined);

  useEffect(() => {
    setFromDemoPage(true);
  });

  useEffect(() => {
    if (loggedIn && accounts.length) {
      setFromAccount(accounts[0]);
    }
  }, [navigate, loggedIn, accounts]);

  const validateRecipient = () => {
    const recipientValue = recipient.trim().toLowerCase();
    const e =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e.test(recipientValue)) {
      return [];
    }
    return [{ type: "email", value: recipientValue }];
  };

  const flashError = (e) => {
    setErrorMessage(e);
    setTimeout(() => {
      setErrorMessage(false);
    }, 3000);
  };

  const [userData, setUserData] = useState({
    name: "",
    title: "",
    company: "",
  });

  const handleInputChange = ({ target: { id, value } }) => {
    setUserData({
      ...userData,
      [id]: value,
    });
  };

  const handleRecipientChange = ({ target: { value } }) => {
    setRecipient(value ? value : "");
    if (
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  const handleSubmit = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();

    try {
      const wrappedRecipient = validateRecipient();
      if (wrappedRecipient.length < 1) {
        flashError("Invalid email");
        return;
      }

      const transferData = {
        account: fromAccount.id,
        amount: { type: "USD", value: "5" },
        card_uuid: uniqueCardsWithCKPreference[0].uuid,
        note: `Neural Payments Demo - name: ${userData.name}, title: ${userData.title}, company: ${userData.company}`,
        recipient: wrappedRecipient,
      };

      const response = await api.post("transfer/outgoing", transferData);
      navigate(`${TRANSFER_PROCESSING.replace(":id", response.data.id)}`);
    } catch (error) {
      if (error.response?.status === 500) {
        console.error("Send money error", error);
        flashError(error.response?.data?.detail);
      } else {
        console.error("An unknown error occurred");
        flashError("An error occurred while sending these funds.");
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  const userInput = (field, handleInput) => {
    return (
      <div style={{ marginRight: "20px" }}>
        <label htmlFor={field}>
          {field.charAt(0).toUpperCase() + field.slice(1)}
        </label>
        <input
          id={field}
          placeholder={`Type ${field}`}
          onChange={handleInput}
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
      <div>
        {userInput("email", handleRecipientChange)}
        {userInput("name", handleInputChange)}
        {userInput("title", handleInputChange)}
        {userInput("company", handleInputChange)}
      </div>
      {errorMessage && <div className="error_message">{errorMessage}</div>}
      <div style={{ marginTop: "30px" }}>
        <button
          disabled={
            !recipient ||
            isSubmitted ||
            !isValidInput ||
            !userData.name ||
            !userData.title ||
            !userData.company
          }
          type="submit"
        >
          Send Payment Now
        </button>
      </div>
    </form>
  );
};

export default NPEventDemo;
