import React, { useState, useEffect, createContext, useCallback } from "react";
import useApi from "./api/api";
// import mockNotificationData from "./notificationfeed.json";
import { datadogRum } from "@datadog/browser-rum";
export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const api = useApi(false);
  const schedulerApi = useApi(true);
  const [accounts, setAccounts] = useState([]);
  const [defaultAccounts, setDefaultAccounts] = useState([]);
  const [cards, setCards] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [uniqueCardsWithCKPreference, setUniqueCardsWithCKPreference] =
    useState([]);
  const [history, setHistory] = useState([]);
  const [scheduledTransfers, setScheduledTransfers] = useState([]);
  const [notificationFeed, setNotificationFeed] = useState([]);
  const [p2pConfig, setP2pConfig] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [fundingToken, setFundingToken] = useState("");
  const [historyLoading, setHistoryLoading] = useState(false);
  const [scheduledTransfersLoading, setScheduledTransfersLoading] =
    useState(false);
  const [defaultAccountsLoading, setDefaultAccountsLoading] = useState(false);
  const [scheduledAlert, setScheduledAlert] = useState(false);

  if (loggedIn && !window.location.host.includes("localhost")) {
    datadogRum.startSessionReplayRecording();
  }

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const response = await api.get("account");
        setAccounts(response.data);
      } catch (error) {
        console.error("get accounts error", error);
      } finally {
      }
    };
    const getCards = async () => {
      try {
        const response = await api.get("card");
        setCards(response.data);
      } catch (error) {
        console.error("get cards error", error);
      } finally {
      }
    };
    const getIdentifiers = async () => {
      try {
        const response = await api.get("identifier");
        setIdentifiers(response.data);
      } catch (error) {
        console.error("get identifiers error", error);
      } finally {
      }
    };
    const getP2pConfig = async () => {
      try {
        const response = await api.get("p2p-config");
        setP2pConfig(response.data);
      } catch (error) {
        console.error("get p2p config error", error);
      } finally {
      }
    };
    if (loggedIn) {
      getCards();
      getAccounts();
      getIdentifiers();
      getP2pConfig();
    }
  }, [api, loggedIn]);

  const getDefaultAccounts = useCallback(async () => {
    if (accounts.length) {
      setDefaultAccountsLoading(true);
      try {
        const response = await Promise.all(
          accounts.map((account) => {
            const accountResponse = api.get(`account/${account.id}/default`);
            return accountResponse;
          })
        );
        setDefaultAccounts(
          response.map(({ data }, j) => {
            return [accounts[j], data];
          })
        );
      } catch (error) {
        console.error("get default accounts error", error);
      } finally {
        setDefaultAccountsLoading(false);
      }
    }
  }, [accounts, api]);

  const getHistory = useCallback(async () => {
    setHistoryLoading(true);
    try {
      const response = await api.get("transfer/history");
      setHistory(response.data.data);
    } catch (error) {
      console.error("get history error", error);
    } finally {
      setHistoryLoading(false);
    }
  }, [api]);

  const getScheduledTransfers = useCallback(async () => {
    setScheduledTransfersLoading(true);
    try {
      const response = await schedulerApi.get("schedules");
      setScheduledTransfers(
        response.data.schedules.filter(({ is_active }) => is_active)
      );
    } catch (error) {
      console.error("get scheduled transfers error", error);
    } finally {
      setScheduledTransfersLoading(false);
    }
  }, [schedulerApi]);

  useEffect(() => {
    if (loggedIn) {
      getHistory();
    }
  }, [getHistory, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      getDefaultAccounts();
    }
  }, [getDefaultAccounts, loggedIn]);

  useEffect(() => {
    if (loggedIn && p2pConfig.scheduled_recurring_enabled) {
      getScheduledTransfers();
    }
  }, [getScheduledTransfers, loggedIn, p2pConfig]);

  const getNotificationsFeed = useCallback(async () => {
    try {
      setNotificationFeed([]);
      const response = await api.get("notification/feed");
      response.data.results
        // use this locally for dummy results
        // mockNotificationData.results
        .filter(({ verb }) => verb !== "transfer_received")
        .map((item) =>
          item.activities.map((each) => {
            setNotificationFeed((notificationFeed) => [
              ...notificationFeed,
              each.data,
            ]);
            return each.data;
          })
        );
    } catch (error) {
      console.error("get notification feed error", error);
    }
  }, [api]);

  useEffect(() => {
    if (loggedIn) {
      getNotificationsFeed();
    }
  }, [getNotificationsFeed, loggedIn]);

  cards.forEach(
    (card) =>
      (card.code = accounts.find((account) =>
        account.card_uuids.includes(card.uuid)
      )?.code)
  );

  const removeDuplicatesCardsWithCKPreference = (array) => {
    const map = new Map();
    // remove cards with the same last_four and keep the one with code "CK" (checking account)
    array.forEach((obj) => {
      if (!map.has(obj.last_four) || obj.code === "CK") {
        map.set(obj.last_four, obj);
      }
    });

    return Array.from(map.values());
  };

  useEffect(() => {
    setUniqueCardsWithCKPreference(
      removeDuplicatesCardsWithCKPreference(cards)
    );
  }, [cards]);

  return (
    <DataContext.Provider
      value={{
        loggedIn,
        setLoggedIn,

        history,
        historyLoading,
        getHistory,

        notificationFeed,
        getNotificationsFeed,

        scheduledTransfers,
        scheduledTransfersLoading,
        getScheduledTransfers,

        defaultAccounts,
        defaultAccountsLoading,
        getDefaultAccounts,

        accounts,

        cards,

        identifiers,

        uniqueCardsWithCKPreference,

        p2pConfig,

        fundingToken,
        setFundingToken,

        scheduledAlert,
        setScheduledAlert,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
