import React from "react";
import { hrefRouter as bannoRouter, isSMSCapable } from "../utils/utils";
import { MOBILE_PROVIDERS } from "../constants/mobileProviders";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";

export const canSendSMS = isSMSCapable();

export const clickSendSms = (messageBody, phoneNum) => {
  const { SEND_SMS_PAGE } = ROUTE_CONSTANTS;
  const mobileProvider = localStorage.getItem("mobileProvider");
  let href = `sms:${phoneNum}?body=${messageBody}`;
  if (mobileProvider === MOBILE_PROVIDERS.BANNO) {
    bannoRouter(encodeURI(href), true);
  } else if (mobileProvider === MOBILE_PROVIDERS.MEA) {
    window.location.href = href;
  } else if (
    mobileProvider === MOBILE_PROVIDERS.ALKAMI &&
    !window.location.pathname.includes(SEND_SMS_PAGE)
  ) {
    href = `${window.location.origin}/app/send-sms?phoneNumber=${phoneNum}&smsMessage=${encodeURIComponent(messageBody)}`;
    const a = document.createElement("a");
    a.href = href;
    a.target = "browser";
    a.click();
  } else {
    href = `sms:${phoneNum}?body=${encodeURIComponent(messageBody)}`;
    const a = document.createElement("a");
    a.href = href;
    a.click();
  }
};

const SendSms = ({ identifier, disabled, message }) => {
  return (
    <>
      {canSendSMS && (
        <button
          href="#"
          disabled={disabled}
          onClick={() => {
            clickSendSms(message, identifier);
          }}
          style={{
            fontSize: "12px",
            height: "32px",
            padding: "0",
            borderRadius: "8px",
            minWidth: "200px",
          }}
        >
          Send instructions via text
        </button>
      )}
    </>
  );
};

export default SendSms;
