import React, { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import phone from "phone";
import useApi from "../api/api";
import { ReactComponent as Clock } from "../assets/ico_clock.svg";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { getOS, isMobile } from "../utils/utils";
import Select from "react-select";
import { ReactComponent as Cancel } from "../assets/ico_cancel.svg";
import { ReactComponent as Alert } from "../assets/ico_alert.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SendMoney = ({
  dropDownStyle,
  dropDownTheme,
  setFromDemoPage,
  isScheduled,
}) => {
  const { TRANSFER_PROCESSING, SCHEDULE_PAYMENT, SCHEDULED_DETAILS } =
    ROUTE_CONSTANTS;
  const {
    accounts,
    uniqueCardsWithCKPreference,
    loggedIn,
    scheduledAlert,
    setScheduledAlert,
    getScheduledTransfers,
    p2pConfig,
  } = useContext(DataContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const api = useApi(false);
  const schedulerApi = useApi(true);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fromAccount, setFromAccount] = useState(undefined);
  const [disableSendToPhone, setDisableSendToPhone] = useState(true);
  const [isValidInput, setIsValidInput] = useState(false);
  const [amount, setAmount] = useState(params.amount || "");
  const [recipient, setRecipient] = useState(params.recipient || "");
  const [chosenCard, setChosenCard] = useState({});
  const [note, setNote] = useState("");
  const [repeatFrequency, setRepeatFrequency] = useState({
    label: "Never",
    value: "NEVER",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const repeatFrequencyOptions = useMemo(
    () => [
      {
        label: "Never",
        value: "NEVER",
      },
      {
        label: "Weekly",
        value: "WEEKLY",
      },
      {
        label: "Monthly",
        value: "MONTHLY",
      },
    ],
    []
  );

  const cardOptions = uniqueCardsWithCKPreference.map((card) => ({
    label: `Card Ending ${card.last_four}`,
    value: card.uuid,
  }));

  useEffect(() => {
    setFromDemoPage(false);
  });

  useEffect(() => {
    // default to first card, user can change. Add label for dropdown logic
    uniqueCardsWithCKPreference.length &&
      setChosenCard({
        label: `Card Ending ${uniqueCardsWithCKPreference[0].last_four}`,
        value: uniqueCardsWithCKPreference[0].uuid,
      });
  }, [uniqueCardsWithCKPreference]);

  useEffect(() => {
    if (getOS() === "macos" || getOS() === "ios" || isMobile()) {
      setDisableSendToPhone(false);
    }
  }, []);

  useEffect(() => {
    // the from account depends on the card chosen
    if (loggedIn && accounts.length) {
      setFromAccount(
        accounts.filter((account) =>
          account.card_uuids.includes(chosenCard?.value)
        )[0]
      );
    }
  }, [loggedIn, accounts, chosenCard]);

  const validateRecipient = () => {
    const recipientType = recipient.includes("@") ? "email" : "phone";

    let recipientValue = recipient.trim().toLowerCase();
    if (recipientType === "phone") {
      if (disableSendToPhone) {
        return [];
      }

      const parsed = phone(recipientValue, { country: "USA" });
      if (!parsed.isValid) {
        return [];
      }
      recipientValue = parsed.phoneNumber;
    } else if (recipientType === "email") {
      const e =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!e.test(recipientValue)) {
        return [];
      }
    }
    return [{ type: recipientType, value: recipientValue }];
  };

  const validateAmount = () => {
    let newError = false;

    const amtVal = parseFloat(amount);
    if (amtVal < 0.0) {
      newError = true;
    }

    // return false = valid, no errors
    return !newError;
  };

  const handleRecipientChange = ({ target: { value } }) => {
    setRecipient(value ? value : "");
    if (
      (value.match(/^[0-9, +, \-, (, )]+$/) != null && value.length > 9) ||
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  useEffect(() => {
    if (
      (params.recipient?.match(/^[0-9, +, \-, (, )]+$/) != null &&
        params.recipient?.length > 9) ||
      params.recipient
        ?.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setIsValidInput(true);
    }
    // we do not need params in this dependency array, ignore warning
    // eslint-disable-next-line
  }, []);

  const flashError = (e) => {
    setErrorMessage(e);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 5000);
  };

  const closeScheduledAlert = () => {
    setScheduledAlert(false);
  };

  const fixScheduledAlert = (e) => {
    e.preventDefault();
    console.log("fix alert");
    // TODO handle scheduled alert issue
  };

  const clickClock = (e) => {
    e.preventDefault();
    navigate(SCHEDULE_PAYMENT);
  };

  const formatDate = (date) => {
    const formatStartDate = new Date(date);
    const year = formatStartDate.getFullYear();
    const month = String(formatStartDate.getMonth() + 1).padStart(2, "0");
    const day = String(formatStartDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    e.preventDefault();

    try {
      const wrappedRecipient = validateRecipient();
      if (wrappedRecipient.length < 1) {
        let errMsg = "Invalid email";
        if (!disableSendToPhone) {
          errMsg = "Invalid email or phone number";
        }
        flashError(errMsg);
        return;
      }
      if (!validateAmount()) {
        flashError("Invalid amount");
        return;
      }

      if (!isScheduled) {
        const transferData = {
          account: fromAccount.id,
          amount: { type: "USD", value: amount },
          card_uuid: chosenCard.value,
          note: note.trim(),
          recipient: wrappedRecipient,
        };
        const response = await api.post("transfer/outgoing", transferData);
        navigate(`${TRANSFER_PROCESSING.replace(":id", response.data.id)}`);
      } else {
        const oneMinuteFromNowLocalDateTime = new Date(
          new Date().getTime() + 1 * 60 * 1000
        );

        const oneMinuteFromNowUTCTimeOnly =
          "T" +
          (
            oneMinuteFromNowLocalDateTime.toISOString().slice(0, -1) + "Z"
          ).split("T")[1];

        let scheduledTransferData = {
          from_card: chosenCard.value,
          from_account: fromAccount.id,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          repeat_frequency: repeatFrequency.value,
          amount: { type: "USD", value: amount },
          recipient: wrappedRecipient[0],
          comment: note.trim(),
        };

        if (repeatFrequency.value === "NEVER") {
          scheduledTransferData = {
            ...scheduledTransferData,
            next_occurrence: startDate + oneMinuteFromNowUTCTimeOnly,
            end_date: null,
          };
        } else if (repeatFrequency.value === "WEEKLY") {
          scheduledTransferData = {
            ...scheduledTransferData,
            next_occurrence:
              new Date(
                oneMinuteFromNowLocalDateTime.getTime() +
                  7 * 24 * 60 * 60 * 1000
              )
                .toISOString()
                .slice(0, -1) + "Z",
          };
        } else if (repeatFrequency.value === "MONTHLY") {
          scheduledTransferData = {
            ...scheduledTransferData,
            next_occurrence:
              new Date(
                oneMinuteFromNowLocalDateTime.getFullYear(),
                oneMinuteFromNowLocalDateTime.getMonth() + 1,
                oneMinuteFromNowLocalDateTime.getDate()
              )
                .toISOString()
                .slice(0, -1) + "Z",
          };
        }
        if (recipient.includes("@")) {
          const response = await schedulerApi.post(
            "schedules",
            scheduledTransferData
          );
          getScheduledTransfers();
          navigate(`${SCHEDULED_DETAILS.replace(":id", response.data.id)}`);
        } else {
          flashError(
            "Scheduled transfers are currently only available to email recipients."
          );
        }
      }
    } catch (error) {
      console.error("Send money error", error);
      if (error.response?.status === 500 || error.response?.status === 400) {
        flashError(error.response?.data?.detail);
      } else {
        console.error("An unknown error occurred");
        flashError("An error occurred while sending these funds.");
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  const today = new Date();
  const dateToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 3);
  const dateTwoDaysFromNow = twoDaysFromNow?.toISOString()?.split("T")[0];

  const oneYearFromToday = new Date();
  oneYearFromToday.setDate(oneYearFromToday.getDate() + 366);
  const dateOneYearFromToday = oneYearFromToday?.toISOString()?.split("T")[0];

  const tenYearsFromToday = new Date();
  tenYearsFromToday.setFullYear(tenYearsFromToday.getFullYear() + 10);
  const dateTenYearsFromToday = formatDate(tenYearsFromToday);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      noValidate={true}
      autoComplete="off"
    >
      {accounts.length && uniqueCardsWithCKPreference.length ? (
        <>
          {scheduledAlert && (
            <div className="alert_message">
              <div style={{ display: "flex" }}>
                <Alert
                  className="fill_sending_bg_primary"
                  style={{ marginRight: "10px" }}
                />
                <div style={{ maxWidth: "70%", textWrap: "balance" }}>
                  There was an issue with a scheduled payment
                  <button
                    className="alert_message"
                    onClick={(e) => fixScheduledAlert(e)}
                  >
                    Fix the Payment
                  </button>
                </div>
              </div>
              <div style={{ width: "auto", height: "20px" }}>
                <Cancel
                  className="fill_sending_text_primary"
                  onClick={() => closeScheduledAlert()}
                />
              </div>
            </div>
          )}
          <label htmlFor="recipient">Recipient</label>
          <input
            id="recipient"
            value={recipient}
            placeholder={
              disableSendToPhone || isScheduled
                ? "Type email"
                : "Type phone number or email"
            }
            onChange={handleRecipientChange}
          />
          <label htmlFor="amount">Amount</label>
          <div
            className="bottom_border_sending_bg_tertiary"
            style={{
              display: "flex",
              marginTop: "-10px",
            }}
          >
            <div
              style={{
                fontSize: "32px",
                fontWeight: 550,
                marginTop: "5px",
              }}
            >
              $
            </div>
            <input
              id="amount"
              value={amount}
              type="number"
              min={1}
              className="bg_sending_bg_primary"
              style={{
                fontSize: "32px",
                fontWeight: 550,
                padding: "0 2px",
              }}
              onChange={(event) => setAmount(event.target.value)}
              onClick={() => validateRecipient()}
              placeholder="0"
            />
          </div>
          <label htmlFor="select_card">From Card</label>
          <Select
            id="select_card"
            options={cardOptions}
            value={chosenCard}
            onChange={(option) => setChosenCard(option)}
            classNamePrefix="dropdown"
            // menuIsOpen={true} // use for styling dev
            styles={dropDownStyle}
            theme={dropDownTheme}
          />
          <label htmlFor="note">Comment (optional)</label>
          <input
            id="note"
            value={note}
            placeholder="Type your comment"
            onChange={(event) => setNote(event.target.value)}
          />
          {isScheduled && (
            <>
              <label htmlFor="start_date">Scheduled date</label>
              <DatePicker
                id="start_date"
                placeholderText="Select scheduled date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={dateTwoDaysFromNow}
                maxDate={dateOneYearFromToday}
              />
              <label htmlFor="repeat_frequency">Repeat</label>
              <Select
                id="repeat_frequency"
                options={repeatFrequencyOptions}
                value={repeatFrequency}
                onChange={(option) => setRepeatFrequency(option)}
                classNamePrefix="dropdown"
                // menuIsOpen={true} // use for styling dev
                styles={dropDownStyle}
                theme={dropDownTheme}
              />
              {repeatFrequency.value !== "NEVER" && (
                <>
                  <label htmlFor="end_date">End date</label>
                  <DatePicker
                    id="end_date"
                    placeholderText="Select end date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={dateTwoDaysFromNow}
                    maxDate={dateTenYearsFromToday}
                  />
                </>
              )}
            </>
          )}
          {errorMessage && <div className="error_message">{errorMessage}</div>}
          {isScheduled ? (
            <div style={{ marginTop: "30px" }}>
              <button
                disabled={
                  !amount ||
                  !recipient ||
                  isSubmitted ||
                  !isValidInput ||
                  !startDate ||
                  startDate < dateToday ||
                  (repeatFrequency.value !== "NEVER" && !endDate) ||
                  (repeatFrequency.value !== "NEVER" && startDate >= endDate)
                }
                type="submit"
                data-cy="save_schedule_button"
              >
                Save
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", marginTop: "30px" }}>
              {p2pConfig.scheduled_recurring_enabled && (
                <button
                  onClick={(e) => clickClock(e)}
                  className="bg_sending_bg_primary border_sending_bg_secondary"
                  style={{
                    width: "66px",
                    marginRight: "20px",
                  }}
                  data-cy="clock_button"
                >
                  <Clock className="fill_sending_bg_secondary" />
                </button>
              )}
              <button
                disabled={!amount || !recipient || isSubmitted || !isValidInput}
                type="submit"
                data-cy="send_money_button"
              >
                Send Payment Now
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="center_page">
          <div>
            <h3>
              Something went wrong while fetching account data. You will not be
              able to send money at this time.
            </h3>
            <h4>Please contact support for assistance</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default SendMoney;
